<template>
  <div>
    <Breadcrumbs page_title="quiz" :items="breadcrumbs" />

    <v-container fluid>
      <v-card v-if="!viewAnswer" style="border-radius: 16px;padding: 15px;">
        <div v-for="(item1, i) in section_Items" :key="i">
          <v-row>
            <v-col cols="12">
              <span style="font-size: 32px;color: #424242;">{{ item1.sectionName }}</span>
            </v-col>
            <v-col cols="12" class="pt-0">
              <span style="font-size: 20px;color: #616161;">{{ item1.sectionDescription }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="11">
              <v-card
                v-for="(item2, i) in item1.questionList"
                :key="i"
                style="background-color: #42424208;border-radius: 8px;box-shadow: unset;"
                class="mb-7"
              >
                <!--Quiz's Question WhereQuestion(item1.sectionId) -->
                <v-row class="ma-1 ml-0">
                  <v-col
                    cols="12"
                    md="10"
                    lg="10"
                    xl="10"
                    style="padding-right: 30px !important;padding-bottom: 10px;padding-left: 17px;"
                  >
                    <span style="font-size: 20px;color: #424242">{{ item2.question }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                    lg="2"
                    xl="2"
                    style="text-align: right;padding-top: 20px;padding-right: 16px;padding-bottom: 10px;"
                  >
                    <span
                      style="font-size: 16px;color: #616161;"
                    >{{ item2.questionScore + " " + $t("score")}}</span>
                  </v-col>
                </v-row>
                <v-divider class="ml-4 mr-4" style="border-color: #7070707f;"></v-divider>
                <!-- QUESTION included images-->
                <v-row v-if="item2.questionImge" class="mt-3">
                  <!-- It is Question's image. -->
                  <v-col class="pb-3" md="12" lg="6" xl="6">
                    <v-img
                      class="ml-5"
                      :aspect-ratio="15 / 10"
                      :width="445"
                      @click="showImgPreview(item2.questionImge)"
                      v-bind:src="item2.questionImge"
                    ></v-img>
                  </v-col>
                  <!-- It is for if Answers are images conditions -->
                  <v-col class="pb-3" md="12" lg="6" xl="6">
                    <v-row v-if="item2.choiceList.find((x) => x.choiceImge != null) ? true : false">
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pr-2 pt-2 pb-2"
                        v-for="(item, i) in item2.choiceList"
                        :key="i"
                      >
                        <v-checkbox
                          class="pt-0"
                          v-if="item2.multipleChoice == true"
                          :label="item.choice"
                          v-model="item.userAnswer"
                          @click="checkCorrectAns(item2.questionId,item.choiceId)"
                          color="#4FB14E"
                          hide-details
                        ></v-checkbox>
                        <v-radio-group
                          v-else
                          hide-details
                          class="pt-0"
                          v-model="item2.radio_UserChoice"
                        >
                          <v-radio
                            @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                            :value="item.choiceId"
                            :label="item.choice"
                            color="#4FB14E"
                          ></v-radio>
                        </v-radio-group>
                        <v-img
                          v-if="item.choiceImge"
                          :key="item.choiceId + 1"
                          class="mb-1 mt-2"
                          :aspect-ratio="14 / 8"
                          :width="205"
                          @click="showImgPreview(item.choiceImge)"
                          :src="item.choiceImge"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- It is for if Answer  are pure text conditions -->
                  <v-row
                    class="pl-4 pb-2"
                    v-if="!item2.choiceList.find((x) => x.choiceImge != null) ? true: false"
                  >
                    <v-col class="pt-1 pb-1">
                      <v-list style="background: transparent;">
                        <!-- Answer Choice List. Choose the correct choice... -->
                        <v-list-item-group multiple class="option_sheet">
                          <!-- WhereChoice(item.questionId) , i -->
                          <template v-for="(item) in item2.choiceList">
                            <!--  -->
                            <v-list-item v-if="item" :key="item.choiceId">
                              <template>
                                <v-list-item-action>
                                  <v-checkbox
                                    v-if="item2.multipleChoice == true"
                                    @click="checkCorrectAns(item2.questionId,item.choiceId)"
                                    v-model="item.userAnswer"
                                    color="#4FB14E"
                                  ></v-checkbox>
                                  <v-radio-group
                                    v-else
                                    hide-details
                                    class="mt-0 pt-0"
                                    v-model="item2.radio_UserChoice"
                                  >
                                    <!-- radio_masterChoice -->
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>
                                </v-list-item-action>

                                <v-list-item-content v-if="item" style="padding-right: 26px;">
                                  <span style="font-size: 16px;color: #424242">{{ item.choice }}</span>
                                  <v-img
                                    v-if="item.choiceImge"
                                    :key="item.choiceId + 1"
                                    class="mb-3"
                                    :aspect-ratio="16 / 9"
                                    :width="170"
                                    @click="showImgPreview(item.choiceImge)"
                                    v-bind:src="item.choiceImge"
                                  ></v-img>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-row>

                <!-- QUESTION does not included images -->
                <v-row v-else class="ma-1">
                  <!-- It is for if Answers are text -->
                  <v-col
                    v-if="!item2.choiceList.find((x) => x.choiceImge != null) ? true: false"
                    class="pt-1 pb-3"
                  >
                    <v-list style="background: transparent;">
                      <!-- Answer Choice List. Choose the correct choice... -->
                      <v-list-item-group multiple class="option_sheet">
                        <!-- WhereChoice(item.questionId) , i -->
                        <template v-for="(item) in item2.choiceList">
                          <!--  -->
                          <v-list-item v-if="item" :key="item.choiceId">
                            <template>
                              <v-list-item-action>
                                <v-checkbox
                                  v-if="item2.multipleChoice == true"
                                  @click="checkCorrectAns(item2.questionId,item.choiceId)"
                                  v-model="item.userAnswer"
                                  color="#4FB14E"
                                ></v-checkbox>
                                <v-radio-group
                                  v-else
                                  hide-details
                                  class="mt-0 pt-0"
                                  v-model="item2.radio_UserChoice"
                                >
                                  <!-- radio_masterChoice -->
                                  <v-radio
                                    @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                    :value="item.choiceId"
                                    color="#4FB14E"
                                  ></v-radio>
                                </v-radio-group>
                              </v-list-item-action>

                              <v-list-item-content v-if="item" style="padding-right: 26px;">
                                <span style="font-size: 16px;color: #424242">{{ item.choice }}</span>
                                <v-img
                                  v-if="item.choiceImge"
                                  :key="item.choiceId + 1"
                                  class="mb-3"
                                  :aspect-ratio="16 / 9"
                                  :width="170"
                                  @click="showImgPreview(item.choiceImge)"
                                  v-bind:src="item.choiceImge"
                                ></v-img>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <!-- It is for if Answers are images -->
                  <v-col v-else class="pb-4 pt-1">
                    <v-row>
                      <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pr-2 pt-2 pb-2"
                        v-for="(item, i) in item2.choiceList"
                        :key="i"
                      >
                        <v-checkbox
                          class="pt-0"
                          v-if="item2.multipleChoice == true"
                          :label="item.choice"
                          v-model="item.userAnswer"
                          @click="checkCorrectAns(item2.questionId,item.choiceId)"
                          color="#4FB14E"
                          hide-details
                        ></v-checkbox>
                        <v-radio-group
                          v-else
                          hide-details
                          class="pt-0"
                          v-model="item2.radio_UserChoice"
                        >
                          <v-radio
                            @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                            :value="item.choiceId"
                            :label="item.choice"
                            color="#4FB14E"
                          ></v-radio>
                        </v-radio-group>
                        <v-img
                          v-if="item.choiceImge"
                          :key="item.choiceId + 1"
                          class="mb-1 mt-2"
                          :aspect-ratio="14 / 8"
                          :width="205"
                          @click="showImgPreview(item.choiceImge)"
                          :src="item.choiceImge"
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="item2.ansNotSubmit" :key="i" class="mt-0">
                  <v-col class="pt-0" style="padding-left: 43px;">
                    <v-icon class="mr-2" color="#D52A2A">mdi-alert-circle-outline</v-icon>
                    <span
                      style="font-size: 16px;color: #D52A2A;"
                    >{{ $t("itisnecessarytoanswerquestion") }}</span>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col style="text-align: right;padding-right: 15px;">
                  <v-btn
                    v-if="nextQuizSection"
                    @click="clickBack()"
                    class="text-capitalize mr-3"
                    style="
                        color: #424242;
                        background-color: #F0F0F0;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("back") }}</v-btn>
                  <v-btn
                    v-if="lastQuizSection"
                    @click="submitQuiz()"
                    class="text-capitalize"
                    style="
                        color:#FFFFFF;
                        background-color:#A6CC39;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("submit") }}</v-btn>
                  <v-btn
                    v-if="!lastQuizSection"
                    @click="clickNext()"
                    class="text-capitalize"
                    style="
                        color:#FFFFFF;
                        background-color:#A6CC39;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("next") }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <!-- view answer detail -->
      <v-card v-else style="border-radius: 16px;padding: 15px;">
        <div v-for="(item1, i) in section_Items" :key="i">
          <v-row>
            <v-col cols="12">
              <span style="font-size: 32px;color: #424242;">{{ item1.sectionName }}</span>
            </v-col>
            <v-col cols="12" class="pt-0">
              <span style="font-size: 20px;color: #616161;">{{ item1.sectionDescription }}</span>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="11" lg="11">
              <v-card
                v-for="(item2, i) in item1.questionList"
                :key="i"
                style="background-color: #42424208;border-radius: 8px;box-shadow: unset;"
                class="mb-7"
              >
                <!--Quiz's Question WhereQuestion(item1.sectionId) -->
                <v-row class="ma-1 ml-0">
                  <v-col
                    cols="11"
                    md="10"
                    lg="10"
                    xl="10"
                    style="padding-right: 30px !important;padding-bottom: 10px;padding-left: 17px;"
                  >
                    <span style="font-size: 20px;color: #424242">{{ item2.question }}</span>
                  </v-col>
                  <v-col
                    cols="11"
                    md="2"
                    lg="2"
                    xl="2"
                    style="text-align: right;padding-top: 20px;padding-right: 16px;padding-bottom: 10px;"
                  >
                    <span
                      style="font-size: 16px;color: #616161;"
                    >{{ item2.thisQuesRusult + " / " + item2.questionScore }}</span>
                  </v-col>
                </v-row>
                <v-divider class="ml-4 mr-4" style="border-color: #7070707f;"></v-divider>
                <!-- QUESTION included images, that will show here.. -->
                <v-row v-if="item2.questionImge" class="mt-3">
                  <!-- It is Question's image. -->
                  <v-col class="pb-3" lg="5" xl="5">
                    <v-img
                      class="ml-5"
                      :aspect-ratio="15 / 10"
                      :width="445"
                      @click="showImgPreview(item2.questionImge)"
                      v-bind:src="item2.questionImge"
                    ></v-img>
                  </v-col>
                  <!-- It is for if Answers are images conditions -->
                  <v-col class="pb-3" lg="7" xl="7">
                    <v-row v-if="item2.choiceList.find((x) => x.choiceImge != null) ? true : false">
                      <v-col
                        cols="11"
                        xs="11"
                        sm="11"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pr-2 pt-2 pb-2"
                        v-for="(item, i) in item2.choiceList"
                        :key="i"
                        style="display: flex;justify-content: flex-start;"
                      >
                        <v-card
                          class="mt-4 ml-3"
                          v-if="item.choiceImge"
                          style="border-radius: 16px;padding-left: 8px;box-shadow: unset;padding-top: 10px;"
                          height="169"
                          width="225"
                          :style="[ show_CorrectAns1 ?
                                    show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                  : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                  : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                    {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                  : {'background' : 'transparent'})                                  
                                  : {}]"
                        >
                          <v-row>
                            <v-col
                              md="10"
                              style="padding-bottom: 11px !important;padding-right: 0;"
                            >
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>

                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>

                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                v-else-if="item2.multipleChoice == true"
                                v-model="item.userAnswer"
                                :label="item.choice"
                                color="#A4A4A4"
                              ></v-checkbox>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col md="2" style="padding-left: 0;">
                              <!-- showing true or false of user choice -->
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                color="#4FB14E"
                              >mdi-check-bold</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                color="#FF6060"
                              >mdi-window-close</v-icon>
                            </v-col>
                          </v-row>

                          <v-img
                            v-if="item.choiceImge"
                            :key="item.choiceId + 1"
                            class="mb-1 mt-2"
                            :aspect-ratio="14 / 8"
                            :width="205"
                            @click="showImgPreview(item.choiceImge)"
                            :src="item.choiceImge"
                          ></v-img>
                        </v-card>

                        <!-- for text choice mix with image choice -->
                        <v-row v-if="item.choiceImge == null" class="mt-3" style="display: unset;">
                          <v-list-item class="pl-7 mr-2">
                            <v-col
                              md="10"
                              style="
                                border-bottom-left-radius: 8px;
                                border-top-left-radius: 8px;
                                padding: 8px;
                                box-shadow: unset;
                                padding-bottom: 11px !important;
                                "
                              :style="[ show_CorrectAns1 ?
                                        show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                      : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                      : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                        {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                      : {'background' : 'transparent'})
                                      : {}]"
                            >
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                v-else-if="item2.multipleChoice == true"
                                v-model="item.userAnswer"
                                :label="item.choice"
                                color="#A4A4A4"
                              ></v-checkbox>
                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col
                              md="2"
                              style="
                                border-bottom-right-radius: 8px;
                                border-top-right-radius: 8px;
                                padding: 8px;
                                box-shadow: unset;
                                padding-left: 0;
                                padding-bottom: 11px !important;
                                "
                              :style="[ show_CorrectAns1 ?
                                        show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                      : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                      : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                        {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                      : {'background' : 'transparent'})                                  
                                      : {}]"
                            >
                              <!-- showing true or false of user choice -->
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                color="#4FB14E"
                              >mdi-check-bold</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                color="#FF6060"
                              >mdi-window-close</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == null"
                                color="transparent"
                              >mdi-window-close</v-icon>
                            </v-col>
                          </v-list-item>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- It is for if Answers are pure text -->
                  <v-col
                    cols="12"
                    lg="12"
                    xl="12"
                    v-if="!item2.choiceList.find((x) => x.choiceImge != null) ? true: false"
                  >
                    <v-row class="pl-4 pb-2 pr-4">
                      <v-col class="pt-1 pb-0">
                        <v-list style="background: transparent;">
                          <!-- Answer Choice List. Choose the correct choice... -->
                          <v-list-item-group multiple class="option_sheet">
                            <!-- WhereChoice(item.questionId) , i -->
                            <template v-for="(item) in item2.choiceList">
                              <!--  -->
                              <v-list-item
                                disabled
                                class="viewans_"
                                v-if="item"
                                :key="item.choiceId"
                                style="border-radius: 8px;height: 32px;margin-bottom: 10px;"
                                :style="[ show_CorrectAns1 ?
                                        show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                      : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                      : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                        {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                      : {'background' : 'transparent'})                                  
                                      : {}]"
                              >
                                <v-list-item-action>
                                  <v-checkbox
                                    disabled
                                    class="disable_green"
                                    v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                    v-model="item.choiceAnswerMaster"
                                    color="#4FB14E"
                                  ></v-checkbox>
                                  <v-checkbox
                                    disabled
                                    class="disable_green"
                                    v-model="item.choiceAnswerMaster"
                                    v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                    color="#4FB14E"
                                  ></v-checkbox>
                                  <v-checkbox
                                    disabled
                                    class="disable_grey"
                                    v-else-if="item2.multipleChoice == true"
                                    v-model="item.userAnswer"
                                    color="#A4A4A4"
                                  ></v-checkbox>
                                  <v-radio-group
                                    disabled
                                    v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                    hide-details
                                    class="mt-0 pt-0 disable_radio_green"
                                    v-model="item2.radio_masterChoice"
                                  >
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>

                                  <v-radio-group
                                    disabled
                                    v-if=" show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                    hide-details
                                    class="mt-0 pt-0 disable_radio_green"
                                    v-model="item2.radio_masterChoice"
                                  >
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>

                                  <v-radio-group
                                    disabled
                                    v-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                    hide-details
                                    class="mt-0 pt-0 disable_radio_grey"
                                    v-model="item2.radio_UserChoice"
                                  >
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>

                                  <v-radio-group
                                    disabled
                                    v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                    hide-details
                                    class="mt-0 pt-0 disable_radio_grey"
                                    v-model="item2.radio_UserChoice"
                                  >
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>

                                  <v-radio-group
                                    disabled
                                    v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false && item.userAnswer == null ? true : false : false"
                                    hide-details
                                    class="mt-0 pt-0 disable_radio_grey"
                                    v-model="item2.radio_UserChoice"
                                  >
                                    <v-radio
                                      @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                      :value="item.choiceId"
                                      color="#4FB14E"
                                    ></v-radio>
                                  </v-radio-group>
                                  <!-- radio bug -->
                                </v-list-item-action>

                                <v-list-item-content v-if="item" style="padding-right: 26px;">
                                  <span style="font-size: 16px;color: #424242">{{ item.choice }}</span>
                                  <v-img
                                    v-if="item.choiceImge"
                                    :key="item.choiceId + 1"
                                    class="mb-3"
                                    :aspect-ratio="16 / 9"
                                    :width="170"
                                    @click="showImgPreview(item.choiceImge)"
                                    v-bind:src="item.choiceImge"
                                  ></v-img>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-icon
                                    v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                    color="#4FB14E"
                                  >mdi-check-bold</v-icon>
                                  <v-icon
                                    v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                    color="#FF6060"
                                  >mdi-window-close</v-icon>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <!--QUESTION does not include images => Both master & user answers-->
                <v-row v-else class="ma-1">
                  <!-- It is for if Answers are text -->
                  <v-col
                    v-if="!item2.choiceList.find((x) => x.choiceImge != null) ? true: false"
                    class="pt-1 pb-3"
                  >
                    <v-list style="background: transparent;">
                      <!-- Answer Choice List. Choose the correct choice... -->
                      <v-list-item-group multiple class="option_sheet">
                        <!-- WhereChoice(item.questionId) , i -->
                        <template v-for="(item) in item2.choiceList">
                          <!--  -->
                          <v-list-item
                            disabled
                            class="viewans_"
                            v-if="item"
                            :key="item.choiceId"
                            style="border-radius: 8px;height: 32px;margin-bottom: 10px;"
                            :style="[ show_CorrectAns1 ?
                                      show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                    : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                    : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                      {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                    : {'background' : 'transparent'})                                  
                                    : {}]"
                          >
                            <v-list-item-action>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                v-model="item.choiceAnswerMaster"
                                color="#4FB14E"
                              ></v-checkbox>

                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                v-model="item.choiceAnswerMaster"
                                color="#4FB14E"
                              ></v-checkbox>

                              <v-checkbox
                                disabled
                                class="disable_grey"
                                v-else-if="item2.multipleChoice == true"
                                v-model="item.userAnswer"
                                color="#A4A4A4"
                              ></v-checkbox>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>
                            </v-list-item-action>

                            <v-list-item-content v-if="item" style="padding-right: 26px;">
                              <span style="font-size: 16px;color: #424242">{{ item.choice }}</span>
                              <v-img
                                v-if="item.choiceImge"
                                :key="item.choiceId + 1"
                                class="mb-3"
                                :aspect-ratio="16 / 9"
                                :width="170"
                                @click="showImgPreview(item.choiceImge)"
                                v-bind:src="item.choiceImge"
                              ></v-img>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                color="#4FB14E"
                              >mdi-check-bold</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                color="#FF6060"
                              >mdi-window-close</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                  <!-- It is for if Answers are images -->
                  <v-col v-else class="pb-4 pt-1">
                    <v-row>
                      <v-col
                        cols="11"
                        xs="11"
                        sm="11"
                        md="6"
                        lg="6"
                        xl="6"
                        class="pr-2 pt-2 pb-2"
                        v-for="(item, i) in item2.choiceList"
                        :key="i"
                        style="display: flex;justify-content: flex-start;"
                      >
                        <v-card
                          class="mt-4 ml-3"
                          v-if="item.choiceImge"
                          style="border-radius: 16px;padding-left: 8px;box-shadow: unset;padding-top: 10px;"
                          height="169"
                          width="225"
                          :style="[ show_CorrectAns1 ?
                                    show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                  : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                  : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                    {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                  : {'background' : 'transparent'})                                  
                                  : {}]"
                        >
                          <v-row>
                            <v-col
                              md="10"
                              style="padding-bottom: 11px !important;padding-right: 0;"
                            >
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>

                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                :label="item.choice"
                                v-model="item.choiceAnswerMaster"
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                color="#4FB14E"
                              ></v-checkbox>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                v-else-if="item2.multipleChoice == true"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#A4A4A4"
                              ></v-checkbox>
                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col md="2" style="padding-left: 0;">
                              <!-- showing true or false of user choice -->
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                color="#4FB14E"
                              >mdi-check-bold</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                color="#FF6060"
                              >mdi-window-close</v-icon>
                            </v-col>
                          </v-row>

                          <v-img
                            v-if="item.choiceImge"
                            :key="item.choiceId + 1"
                            class="mb-1 mt-2"
                            :aspect-ratio="14 / 8"
                            :width="205"
                            @click="showImgPreview(item.choiceImge)"
                            :src="item.choiceImge"
                          ></v-img>
                        </v-card>

                        <!-- for text choice mix with image choice -->
                        <v-row v-if="item.choiceImge == null" class="mt-3" style="display: unset;">
                          <v-list-item class="pl-7 mr-2">
                            <v-col
                              md="10"
                              style="
                                border-bottom-left-radius: 8px;
                                border-top-left-radius: 8px;
                                padding: 8px;
                                box-shadow: unset;
                                padding-bottom: 11px !important;
                                "
                              :style="[ show_CorrectAns1 ?
                                        show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                      : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                      : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                        {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                      : {'background' : 'transparent'})
                                      : {}]"
                            >
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_green mt-0"
                                v-if="item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer != null"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#4FB14E"
                              ></v-checkbox>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                :label="item.choice"
                                v-model="item.choiceAnswerMaster"
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == true && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                color="#4FB14E"
                              ></v-checkbox>
                              <v-checkbox
                                disabled
                                hide-details
                                class="disable_grey mt-0"
                                v-else-if="item2.multipleChoice == true"
                                v-model="item.choiceAnswerMaster"
                                :label="item.choice"
                                color="#A4A4A4"
                              ></v-checkbox>
                              <v-radio-group
                                disabled
                                v-if=" show_CorrectAns2 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == null) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-if="show_CorrectAns2 || show_CorrectAns1 ? (item2.multipleChoice == false && item.choiceAnswerMaster == true && item.userAnswer == true) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_green"
                                v-model="item2.radio_masterChoice"
                              >
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="item2.multipleChoice == false && item.userAnswer == true && item.choiceAnswerMaster == false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_UserChoice">
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns2 ? (item2.multipleChoice == false && item.userAnswer == null && item.choiceAnswerMaster == false) ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_masterChoice"
                              >
                                <!-- radio_UserChoice -->
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>

                              <v-radio-group
                                disabled
                                v-else-if="show_CorrectAns1 && !show_CorrectAns2 ? item2.multipleChoice == false ? true : false : false"
                                hide-details
                                class="mt-0 pt-0 disable_radio_grey"
                                v-model="item2.radio_masterChoice"
                              >
                                <!-- radio_UserChoice -->
                                <v-radio
                                  @click="checkCorrectAns(item2.questionId,item.choiceId),item.userAnswer = true"
                                  :value="item.choiceId"
                                  :label="item.choice"
                                  color="#4FB14E"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                            <v-col
                              md="2"
                              style="
                                border-bottom-right-radius: 8px;
                                border-top-right-radius: 8px;
                                padding: 8px;
                                box-shadow: unset;
                                padding-left: 0;
                                padding-bottom: 11px !important;
                                "
                              :style="[ show_CorrectAns1 ?
                                        show_CorrectAns2 ? (item.choiceAnswerMaster == true ? {'background' : '#A6CC391C'} 
                                      : item.userAnswer == true ?{'background' : '#FF60601F'} : {'background' : 'transparent'})
                                      : (item.userAnswer != null ? item.userAnswer == true ? item.choiceAnswerMaster == true ? 
                                        {'background' : '#A6CC391C'} : {'background' : '#FF60601F'} : {'background' : '#FF60601F'} 
                                      : {'background' : 'transparent'})                                  
                                      : {}]"
                            >
                              <!-- showing true or false of user choice -->
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == true"
                                color="#4FB14E"
                              >mdi-check-bold</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == false && item.userAnswer == true"
                                color="#FF6060"
                              >mdi-window-close</v-icon>
                              <v-icon
                                v-if="item.choiceAnswerMaster == true && item.userAnswer == null"
                                color="transparent"
                              >mdi-window-close</v-icon>
                            </v-col>
                          </v-list-item>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-row>
                <v-col style="text-align: right;padding-right: 15px;">
                  <v-btn
                    v-if="nextQuizSection"
                    @click="clickBack()"
                    class="text-capitalize mr-3"
                    style="
                        color: #424242;
                        background-color: #F0F0F0;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("back") }}</v-btn>
                  <v-btn
                    v-if="lastQuizSection"
                    @click="$router.push('/course')"
                    class="text-capitalize"
                    style="
                        color:#FFFFFF;
                        background-color:#A6CC39;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("qfinished") }}</v-btn>
                  <v-btn
                    v-if="!lastQuizSection"
                    @click="clickNext()"
                    class="text-capitalize"
                    style="
                        color:#FFFFFF;
                        background-color:#A6CC39;
                        font-size: 16px;
                        border-radius: 8px;
                        box-shadow: unset;"
                  >{{ $t("next") }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-container>

    <v-dialog width="450" v-model="resultDialog" persistent>
      <v-card style="border-radius: 16px;">
        <v-card-title v-if="show_CorrectAns1 || show_score || showPassingScore" class="pt-2 pb-1">
          <v-row v-if="show_score || !passQuiz" justify="center" no-gutters>
            <v-col>
              <span style="font-size:20px;color: #363636;font-weight: bold;">{{ $t("result") }}</span>
            </v-col>
            <v-col style="text-align: right;">
              <v-btn
                @click="resultDialog = false,$router.push('/course')"
                style="
                border-radius: 8px !important;
                border:1px solid rgba(66, 66, 66, 0.05);
                background-color: transparent;
                color: #424242;
                box-shadow: unset;
                width: 23px;"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else justify="center" no-gutters>
            <v-col style="text-align: center;" class="pt-1 pb-1">
              <span
                class="mt-2"
                style="font-size:20px;color: #363636;"
              >{{ $t("thankyoufordoingquiz") }}</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-title v-else>
          <v-row justify="center" no-gutters>
            <v-col style="text-align: center;" class="pt-1 pb-1">
              <span
                class="mt-2"
                style="font-size:20px;color: #363636;"
              >{{ $t("thankyoufordoingquiz") }}</span>
            </v-col>
          </v-row>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text v-if="show_score || !passQuiz" class="pb-1 pt-2">
          <p
            v-if="!passQuiz && $route.query.quizIsPassed == 0"
            class="pt-1 text-left mb-0"
            style="font-size: 18px; color: #424242;"
          >{{ $t("notpassquiz") }}</p>
          <p
            v-if="show_score && passQuiz"
            class="pt-1 mb-0 text-left"
            style="font-size: 16px; color: #424242;"
          >{{$t("thankyoufordoingquiz")}}</p>
          <p
            v-if="show_score"
            class="pt-1 text-left"
            style="font-size: 16px; color: #424242;"
          >{{$t("scoresyouhaveearned")}}</p>
          <!-- && passQuiz -->
          <p
            v-if="show_score"
            class="pt-4 text-center mt-7"
            style="font-size: 56px;"
            :style="[passQuiz ? {'color': '#4FB14E'} : {'color': '#FF6060'}]"
          >{{ user_score +"/"+ full_score}}</p>
          <p
            v-if="showPassingScore"
            class="pt-4 text-center"
            style="font-size: 20px; color: #616161"
          >{{ $t("passingscore") + passing_score }}</p>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center" no-gutters>
            <v-btn
              v-if="!passQuiz && $route.query.quizIsPassed == 0"
              color="#FF6060"
              style="font-size: 16px;border-radius: 8px;box-shadow: unset;"
              class="text-capitalize white--text mt-1 mb-3"
              @click="doQuizAgain()"
            >{{ $t("requiz") }}</v-btn>
            <v-btn
              v-else-if="show_CorrectAns1"
              color="#A6CC39"
              style="font-size: 16px;border-radius: 8px;box-shadow: unset;"
              class="text-capitalize white--text mt-1 mb-3"
              @click="viewAnswerResult()"
            >{{ $t("viewanswer") }}</v-btn>
            <v-btn
              v-else
              color="#4FB14E"
              style="font-size: 16px;border-radius: 8px;box-shadow: unset;"
              class="text-capitalize white--text mt-1 mb-1"
              @click="$router.push('/course'),resultDialog = false"
            >{{ $t("ok") }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" persistent width="300">
      <v-card color="#4FB14E" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#ffffff" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important;">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg= false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border:1px solid #FFFFFF30; "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius:8px; margin:auto;"
          :src="picturePreview"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      //quiz setting..
      show_CorrectAns1: false, // highlight only user's answer
      show_CorrectAns2: false, //answer After Completion hight
      showPassingScore: false,
      show_score: false,
      //.......................
      previewimg: false,
      picturePreview: null,
      viewAnswer: false,
      user_score: 0,
      full_score: 0,
      passing_score: 0,
      passQuiz: true,
      resultTitle: "",
      resultDialog: false,
      lastQuizSection: false,
      nextQuizSection: false,
      //radio_masterChoice: [],
      quizSectionData: [], //which will include all section, question etc...
      quiz_Data: [],
      QuizID: 0,
      dateoffset: new Date().getTimezoneOffset(),
      section_Items: [],
      question_Items: [],
      choice_Items: [
        {
          show_imgBtn: false, //show image in each row
          choiceNo: 1,
          choice: "Option",
          choiceImge: null,
          choiceAnswerMaster: null,
          userAnswer: null,
          rule: false
        }
      ], //"Option 1", "Option 2", "", "Option 3", "Option 4"
      breadcrumbs: [
        {
          text: "course",
          href: "/course"
        },
        {
          text: "quiz"
        }
      ]
    };
  },
  mounted() {
    this.GetQuizMasterDetail();
    this.getNotificationCount(); //for noti & favourite count if page got refresh
    this.getFavourite();
  },
  methods: {
    showImgPreview(image) {
      this.previewimg = true;
      this.picturePreview = image;
    },

    viewAnswerResult() {
      this.resultDialog = false; // hide result dialog.
      this.viewAnswer = true;
      //push first index 0 to show first
      this.section_Items = [];
      this.section_Items.push(this.quizSectionData[0]);

      // button controls...
      this.lastQuizSection = this.quizSectionData.length > 1 ? false : true; // hide submit button
      this.nextQuizSection = false; // won't show back button when start the quiz again
      this.resultDialog = false; // hide score dialog
    },

    doQuizAgain() {

      this.$router.push({
        name: "course",
        params: {
          rq_courseId: this.$route.query.rq_courseId,
          reQuiz: "requiz",
          courseTemp: this.$route.query.courseTemp
        }
      });

      this.section_Items = [];
      this.quizSectionData = this.quizSectionData.map(x => ({
        ...x,
        questionList: x.questionList.map(v => ({
          ...v,
          // radio_masterChoice: 0,
          radio_UserChoice: 0,
          choiceList: v.choiceList.map(i => ({
            ...i,
            userAnswer: null
          }))
        }))
      }));
      this.section_Items.push(this.quizSectionData[0]);
      this.lastQuizSection = this.quizSectionData.length > 1 ? false : true; // hide submit button
      this.nextQuizSection = false; // won't show back button when start the quiz again
      this.resultDialog = false; // hide score dialog
    },

    clickBack() {
      let currentSectionIndex = this.quizSectionData
        .map(function(e) {
          return e.sectionId;
        })
        .indexOf(this.section_Items[0].sectionId);

      if (this.quizSectionData[currentSectionIndex - 1] != undefined) {
        this.quizSectionData[currentSectionIndex] = this.section_Items[0];

        //pushing edited section data to this.section_Items
        this.section_Items = [];
        this.section_Items.push(this.quizSectionData[currentSectionIndex - 1]);

        this.nextQuizSection = currentSectionIndex - 1 != 0 ? true : false; // if its firt index hide next button.
        this.lastQuizSection = false; // when going back to previous section, hide submit button..
      } 
    },

    clickNext() {
      //to go to next quiz section....
      //1.first save user's edit answer from section_Items to QuizData by matching sectionId
      let currentSectionIndex = 0;


      for (let question of this.section_Items[0].questionList) {
        let notChooseAns2 = question.choiceList.find(k => k.userAnswer === true)
          ? false
          : true;
        question.ansNotSubmit = notChooseAns2 === false ? false : true;
      }

      let answerNotSubmit = this.section_Items[0].questionList.find(x => {
        return x.ansNotSubmit === true ? true : false;
      })
        ? this.section_Items[0].questionList.find(x => {
            return x.ansNotSubmit === true ? true : false;
          })
        : null;

      if (answerNotSubmit) {
        //user did not submit/choose any answer so cannot pass through
        return;
      }

      currentSectionIndex = this.quizSectionData
        .map(function(e) {
          return e.sectionId;
        })
        .indexOf(this.section_Items[0].sectionId);
      if (this.quizSectionData[currentSectionIndex + 1] != undefined) {
        //enter this condition because there is still next available section.

        //put update data into its index .....
        this.quizSectionData[currentSectionIndex] = this.section_Items[0];

        //pushing new or next section data to this.section_Items
        this.section_Items = [];
        this.section_Items.push(this.quizSectionData[currentSectionIndex + 1]);

        this.nextQuizSection = currentSectionIndex + 1 != 0 ? true : false;

        if (
          this.section_Items[0].sectionId ==
          this.quizSectionData[this.quizSectionData.length - 1].sectionId
        ) {
          this.lastQuizSection = true;
        } else {
          this.lastQuizSection = false;
        }
      }
    },

    checkCorrectAns(que_id, cho_id) {
      //finding current section's question's choices
      let current_Que = this.section_Items[0].questionList.filter(
        x => x.questionId == que_id
      );

      for (let item of current_Que) {

        for (let subitem of item.choiceList) {
          if (item.multipleChoice === false) {
            // for single choice ans
            if (subitem.choiceId == cho_id) {
              subitem.userAnswer = true;
            } else {
              subitem.userAnswer = null;
            }
          } else {
            // for multiple choice ans
            if (subitem.choiceId == cho_id) {
              subitem.userAnswer = subitem.userAnswer === false ? null : true;
            }
          }
        }

        let notChooseAns2 = item.choiceList.find(k => k.userAnswer === true) ? false : true;
        item.ansNotSubmit = notChooseAns2 === false ? false : true;
      }
    },

    async GetQuizMasterDetail() {
      let that = this;

      that.itemsList = [];
      that.loading = true;
      let request = {
        quizId: that.$route.query.quizId, //quiz id will come from course
        companyId: localStorage.getItem("companyID"),
        mobile: true,
        createOrUpdateBy: localStorage.getItem("userName"),
        offset: 0
      };
      await axios
        .post(`${that.web_urlV4}Quiz/GetQuizMasterDetail`, request)
        .then(function(response) {
          if (response.data.status == 0) {
            that.quiz_Data = response.data.data;
            let temp = response.data.data.sectionList;
            that.lastQuizSection = temp.length > 1 ? false : true; //whether to show submit or next btn.

            //at the beginning only first secton will show..

            that.quizSectionData = temp.map(v => ({
              ...v,
              questionList: v.questionList.map(x => ({
                ...x,
                ansNotSubmit: false,
                thisQuesRusult: 0,
                radio_UserChoice: 0, // user's radio answer
                radio_masterChoice:
                  x.multipleChoice === false
                    ? x.choiceList.find(k => k.choiceAnswerMaster === true)
                      ? x.choiceList.find(k => k.choiceAnswerMaster === true)
                          .choiceId
                      : 0
                    : 0 // master's radio answer
              }))
            }));

            that.section_Items.push(that.quizSectionData[0]);
          }

          that.full_score = response.data.data.fullScore;
          that.showPassingScore = response.data.data.showPassingScore; // show passing score or not
          that.passing_score = response.data.data.passingScore
            ? response.data.data.passingScore
            : 0;
          that.show_CorrectAns1 =
            that.quiz_Data.showCorrectAnswerAfterCompletion;
          that.show_CorrectAns2 = that.quiz_Data
            .showCorrectAnswerAfterCompletion
            ? that.quiz_Data.showCorrectAnswer
            : false;
          that.show_score = that.quiz_Data.showScore;
          that.showPassingScore = that.quiz_Data.showPassingScore;

          that.loading = false;
        })
        .catch(function(err) {
          that.loading = false;
          throw err;
        });
      that.loading = false;
    },

    async submitQuiz() {
      let userScore = 0;
      let answerNotSubmit = null;

      for (let section of this.quizSectionData) {
        for (let question of section.questionList) {
          if (question.multipleChoice === false) {
            let ans = question.choiceList.find(
              x => x.choiceAnswerMaster === true && x.userAnswer === true
            )
              ? question.choiceList.find(
                  x => x.choiceAnswerMaster === true && x.userAnswer === true
                ).choiceId
              : null;
            if (ans == question.radio_masterChoice) {
              userScore += question.questionScore;
              question.thisQuesRusult = question.questionScore;
            }

            let notChooseAns1 = question.choiceList.find(k => k.userAnswer === true) ? false : true;
            question.ansNotSubmit = notChooseAns1 === false ? false : true;

          } else {
            let correctAns = 0;
            let masterAns = 0;
            let wrongAns = 0;

            for (let choice of question.choiceList) {
              if (choice.choiceAnswerMaster === true) {
                //keep tracks of true master ans
                masterAns += 1;
              }

              if (
                choice.choiceAnswerMaster === true &&
                choice.userAnswer === true
              ) {
                // user choose correct answer
                correctAns += 1;
              }

              if (
                choice.choiceAnswerMaster === false &&
                choice.userAnswer === true
              ) {
                // user choose incorrect answers
                // this condition is for when user might already choose correct ans but
                // user choose all choice or choose more than correct ans
                // and don't want to take score for it, if user not choose the exact same correct ans
                wrongAns += 1;
              }
            } //end choiceList For Loop

            let notChooseAns = question.choiceList.find(k => k.userAnswer === true) ? false : true;

            question.ansNotSubmit = notChooseAns === false ? false : true;

            if (wrongAns != 0) {
              userScore += 0;
              question.thisQuesRusult = 0;
            } else if (masterAns == correctAns) {
              //score will be counted if user's correct ans and master's ans count is matched.

              userScore += question.questionScore;
              question.thisQuesRusult = question.questionScore;
            }
          }
        }

        answerNotSubmit = section.questionList.find(x => {
          return x.ansNotSubmit === true ? true : false;
        });
      }
      if (answerNotSubmit) {
        //user did not sumbit ans to some questions.. cannot submit
        return;
      }

      this.user_score = userScore;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      const memberid = userdata.memberId;
      let temp_quiz = {
        quizId: this.quiz_Data.quizId,
        courseId: this.quiz_Data.courseId,
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
        quizName: this.quiz_Data.quizName,
        courseName: this.quiz_Data.courseName,
        createOrUpdateBy: memberid,
        showCorrectAnswerAfterCompletion: this.quiz_Data
          .showCorrectAnswerAfterCompletion,
        showCorrectAnswer: this.quiz_Data.showCorrectAnswer,
        showScore: this.quiz_Data.showScore,
        score: this.user_score, // userScore,// this.quiz_Data.score,
        fullScore: this.quiz_Data.fullScore,
        passingScore: this.quiz_Data.passingScore,
        showPassingScore: this.quiz_Data.showPassingScore,
        quizType: this.$route.query.quizTypeID,
        sectionList: this.quizSectionData.map(k => ({
          ...k,
          questionList: k.questionList.map(x => ({
            //...x,
            questionId: x.questionId,
            questionNo: x.questionNo,
            questionScore: x.questionScore,
            question: x.question,
            questionImge: x.questionImge,
            multipleChoice: x.multipleChoice,
            choiceList: x.choiceList.map(v => ({
              //...v,
              choiceId: v.choiceId,
              choiceNo: v.choiceNo,
              choice: v.choice,
              choiceImge: v.choiceImge,
              choiceAnswerMaster: v.choiceAnswerMaster,
              userAnswer: v.userAnswer
            }))
          }))
        }))
      };
      this.loading = true;
      //Before submit, whether to submit or show re-quiz
      this.passQuiz = userScore >= this.quiz_Data.passingScore ? true : false;
     
      //submit the quiz whether pass or fail
      const res = await this.$axios.post(
        `${this.web_urlV4}Quiz/AddUserQuiz`,
        temp_quiz
      );

      if (res.data.status == 0) {
        //SHOW RESULT DIALOG
        this.resultDialog = true; // showing result dialog
        this.loading = false;
      } else {
        alert("Something went wrong when submit!");
      }

    }
  }
};
</script>

<style scoped>
/* disable radio color */
::v-deep .disable_radio_green.v-input--radio-group.theme--light.v-input--is-disabled {
  color: #4fb14e !important;
}
::v-deep .disable_radio_grey.v-input--radio-group.theme--light.v-input--is-disabled {
  color: #a4a4a4 !important;
}
/* end disabled radio color */
/* disabled checkbox color */
::v-deep .disable_green.v-input--checkbox.theme--light.v-input--is-disabled {
  color: #4fb14e !important;
}
::v-deep .disable_grey.v-input--checkbox.theme--light.v-input--is-disabled {
  color: #a4a4a4 !important;
}
/* end disabled checkbox */
::v-deep .viewans_.v-list-item {
  min-height: 32px !important;
}
::v-deep .option_sheet.v-list-item-group .v-list-item--active {
  color: #ffff;
}
::v-deep .v-image__image--cover {
  border-radius: 14px;
}
::v-deep .v-btn:not(.v-btn--round).v-size--default {
  min-width: unset !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: transparent !important;
}
::v-deep .v-btn__content {
  padding-top: 2px !important;
}
</style>